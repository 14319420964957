import blank from '../experiments/Blank.logo';
//import FMA from '../experiments/FMA.logo';
import Absorption from '../experiments/Absorption.logo';
import ModelMolecules from '../experiments/ModelMolecules.logo';
import BasicBoard from '../experiments/BasicBoard.logo';
import Motion from '../experiments/Motion.logo';
import LightShow from '../experiments/LightShow.logo';
import Position from '../experiments/Position.logo';

export const experimentsList = [
    {
        name: "Blank",
        code: blank,
        view: 'main'
    },
    {
        name:"Light Show",
        code: LightShow,
        view: 'main'
    },
    {
        name: "BasicBoard",
        code: BasicBoard,
        view: 'main'
    },
    {
        name: "Force & Motion",
        code: Motion,
        view: 'graph'
    },
    // {
    //     name: "Absorption",
    //     code: Absorption,
    //     view: 'main'
    // },
    {
        name: "Modeling Molecules",
        code: ModelMolecules,
        view: 'main'
    },
    {
        name:"Position",
        code: Position,
        view: 'graph'
    },
];