export const includes = 
`
    to receive-packet
        if (is-defined '_packet-length') = false
        [
            make '_packet-length' 6
        ]

        make '_checksum' 0

        make '_packet-type' 'T1'
        ;always T1 right now because these aren't defined yet

        let "i 0
        make '_last-packet' :_packet-type
        make "_last-packet se :_last-packet now ;replacing 'now' with 'now_milli' to get more accurate time
        make "_last-packet se :_last-packet :_packet-length

        repeat ( :_packet-length ) [
            let "_adc-holder readADC :i
            make "_last-packet se :_last-packet ( :_adc-holder )
            let 'i' :i + 1
            make "_checksum :_checksum + (:_adc-holder)
        ]

        make "_last-packet se :_last-packet :_checksum

        if (is-defined '_packet-log') = false 
        [
            make '_packet-log' false
        ]

       if (:_packet-log = true)[
            logData :_last-packet
        ]

    end

    to recievePacket 
        ;This is the new iteration of recieve-packet that includes the ping sensor and 
        ;the date and time in a more readable format. It also handles time in milliseconds.
        
        if readADC0 > 0 [

            ifelse is-defined "_last-packet [
                let "date nth 0 :_last-packet
                let "time nth 1 :_last-packet
                let "deltaT deltaTime :date :time
            ]
            [
                let "deltaT 0
            ]
            make '_packet-length' 6
            make '_checksum' 0

            let "i 0
            let "date_time now_milli
            let "date nth 0 :date_time
            let "time nth 1 :date_time
            make '_last-packet' :date
            make "_last-packet se :_last-packet :time
            make "_last-packet se :_last-packet :deltaT

            repeat ( :_packet-length ) [
                let "_adc-holder readADC :i
                make "_last-packet se :_last-packet ( :_adc-holder )
                let 'i' :i + 1
                make "_checksum :_checksum + (:_adc-holder)
            ]

            let "_last-packet se :_last-packet readPing

            if (is-defined '_packet-log') = false 
            [
                make '_packet-log' false
            ]

            if (:_packet-log = true)[
                logData :_last-packet
            ]
        ]
    end

    to recievePacketInCelcius
        make '_packet-length' 6
        make '_checksum' 0

        make '_packet-type' 'T1'
        ;always T1 right now because these aren't defined yet

        let "i 0
        make '_last-packet' :_packet-type
        make "_last-packet se :_last-packet now
        make "_last-packet se :_last-packet :_packet-length

        repeat ( :_packet-length ) [
            let "_adc-holder readADC :i
            ADUtoCelcius :_adc-holder
            let "x readADUAsCelcius :i
            print 'ADU: '
            print :x
            let "_adc-holder :output
            make "_last-packet se :_last-packet ( :_adc-holder )
            let 'i' :i + 1
            make "_checksum :_checksum + (:_adc-holder)
        ]

        make "_last-packet se :_last-packet :_checksum

        if (is-defined '_packet-log') = false 
        [
            make '_packet-log' false
        ]

       if (:_packet-log = true)[
            logData :_last-packet
        ]
    
    end

    to ADUtoCelcius :adu
    
        make "output ( :adu * 5 ) / 1024.9
        make "output ( :output - 0.5 ) * 100
    
    end

    to set-packet-count :n
        make '_packet-length' :n
    end

    to set-packet-save :value
        make '_packet-log' :value
    end

    to readLightSensor
        output readADC2
    end

    to readTempSensor0
        output readADC0
    end

    to readTempSensor1
        output readADC1
    end

    to readTempSensor2
        output readADC2
    end

    to showxy
        print tcor
    end




`;
